'use strict'; 

new WOW().init();

function onScroll(event) {
  var scrollPos = $(document).scrollTop();
  $('#BulletNav a').each(function () {
    var currLink = $(this);
    var refElement = $(currLink.attr("href"));

    if (refElement.position() !== undefined) {
      if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
        $('#BulletNav a').removeClass("active");
        currLink.addClass("active");
      } else {
        currLink.removeClass("active");
      }
    }
  });
}

$(function () {
  var popup = $("#popup");
  var frame = $("#popupFrame");
  $('.closepopup').on('click', function (e) {
    e.preventDefault();
    $('body').removeClass('noscroll');
    frame.attr("src", "about:blank");
  });
  $('.openpopup').on('click', function (e) {
    e.preventDefault();
    $('body').addClass('noscroll');

    if ($(this).data('src')) {
      var src = $(this).data('src');
    } else {
      var src = $(this).attr('href');
    }

    var title = $(this).data('title');
    $('#putitle').text(title);
    frame.attr("src", src + "?hidemenu");
  }); 

  $('.project-toggle').on("click", function (e) {
    e.preventDefault();
    $('#' + $(this).data('expander') + '-menu').slideToggle();
    $('#' + $(this).data('expander')).slideToggle();
  }); 

  $(".morelink").on("click", function (e) {
    e.preventDefault();
    $(this).toggleClass('expanded');
    var id = $(this).data('id');
    console.log(id);
    $("#more" + id).slideToggle();
    return false;
  });
  $(document).on("scroll", onScroll); 

  $('#BulletNav a[href^="#"]').on('click', function (e) {
    e.preventDefault();
    $(document).off("scroll");
    $('a').each(function () {
      $(this).removeClass('active');
    });
    $(this).addClass('active');
    var target = this.hash,
        menu = target;
    var $target = $(target);
    $('html, body').stop().animate({
      'scrollTop': $target.offset().top - 40
    }, 800, 'swing', function () {
      $(document).on("scroll", onScroll);
    });
  });

  var url = $("#VideoOnModal").attr('src');

  $("#VideoPopUp").on('hide.bs.modal', function () {
    $("#VideoOnModal").attr('src', '');
  });

  $("#VideoPopUp").on('show.bs.modal', function () {
    $("#VideoOnModal").attr('src', url);
  });
  jQuery('img.svg').each(function () {
    var $img = jQuery(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');
    jQuery.get(imgURL, function (data) {
      var $svg = jQuery(data).find('svg'); 

      if (typeof imgID !== 'undefined') {
        $svg = $svg.attr('id', imgID);
      } 


      if (typeof imgClass !== 'undefined') {
        $svg = $svg.attr('class', imgClass + ' replaced-svg');
      } 


      $svg = $svg.removeAttr('xmlns:a'); 

      $img.replaceWith($svg);
    }, 'xml');
  }); 

  var $container = $('#isotopBox').isotope({
    itemSelector: '.item',
    isFitWidth: true
  });
  $container.isotope({
    filter: '*'
  });
  $('.filters').on('click', 'button', function () {
    var filterValue = $(this).attr('data-filter');
    $('.filters button.FilterActive').removeClass('FilterActive');
    $(this).addClass('FilterActive');
    $container.isotope({
      filter: filterValue
    });
  });
  $(window).resize(function () {
    $container.isotope({
      columnWidth: '.col-sm-6.col-md-4.col-lg-3'
    });
  });
  $('.level2menu').hide();
  $('.level3menu').hide();
  $('.firstlevel').on('click', function (e) {
    var id = $(this).attr('href');
    e.preventDefault();
    $('.level2menu').hide();
    $(id).show();
    $('#navbarNavDropdown').removeClass('level3');
    $('#navbarNavDropdown').addClass('level2');
  });
  $('.secondlevel').on('click', function (e) {
    var id = $(this).attr('href');
    e.preventDefault();
    $('.level3menu').hide();
    $(id).show();
    $('#navbarNavDropdown').addClass('level3');
  });
  $('.backlink').on('click', function (e) {
    e.preventDefault();

    if ($('#navbarNavDropdown').hasClass('level3')) {
      level2();
    } else {
      level1();
    }
  });

  function level2() {
    $('.level3menu').hide();
    $('#navbarNavDropdown').removeClass('level3');
  }

  function level3() {
    $('#navbarNavDropdown').removeClass('level3');
    $('#navbarNavDropdown').addClass('level2');
    $('.level2menu').hide();
  }

  function level1() {
    $('.level2menu').hide();
    $('.level3menu').hide();
    $('#navbarNavDropdown').removeClass('level3');
    $('#navbarNavDropdown').removeClass('level2');
  }
});